import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchReleaseById } from '../features/releases/releasesActions';
import { useParams } from 'react-router-dom';
import UrlFirebase from '../components/firebase/urlFirebase'

import { PlayCircleIcon, PauseCircleIcon } from '@heroicons/react/24/outline';
import { setPlayerReleaseId, setTrackList, setCurrentIndex, setIsPlaying, setPlayerDisplay } from '../features/audioplayer/playerSlice';

import Banner_Footer_BW_New from "../assets/images/Footer Banner Release page Visual.png"

import Banner_Outro_Left_BW from "../assets/images/Banner Outro_BW_01.png"
import Banner_Outro_BW from "../assets/images/Banner Outro_BW_02.png"
import Banner_Outro_Right_BW from "../assets/images/Banner Outro_BW_03.png"

import big_player_gradient1 from "../assets/motion/Big Player Gradient Motion 01.mp4"
import big_player_gradient2 from "../assets/motion/Big Player Gradient Motion 02.mp4"
import big_player_gradient3 from "../assets/motion/Big Player Gradient Motion 03.mp4"

function Release() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const containerRef = useRef(null);

  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);

  const { playerReleaseId, currentIndex, isPlaying } = useSelector(state => state.player);
  const { item: release, loading, error } = useSelector(state => state.releases);

  const [hideContent, setHideContent] = useState(false);
  const [bgPlayer, setBgPlayer] = useState(null);

  const handleScrollToTop = () => {
    if (!hideContent) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (containerRef.current) {
        containerRef.current.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  };

  const playTrackFromIndex = (index) => {
    dispatch(setPlayerReleaseId(id));
    dispatch(setTrackList(release.tracklist));
    dispatch(setPlayerDisplay(true));
    if (index === currentIndex) {
      if (isPlaying) {
        dispatch(setIsPlaying(false));
      } else {
  
        dispatch(setIsPlaying(true));
        handleScrollToTop()
        setTimeout(() => {
          setBgPlayer(getRandomVideo());
          setHideContent(true)
        }, 300);
      }
    } else {
      
      dispatch(setCurrentIndex(index));
      dispatch(setIsPlaying(true));
      handleScrollToTop()
      setTimeout(() => {
        setHideContent(true)
        setBgPlayer(getRandomVideo());
      }, 300);
    }
  };
  
  const closePlayer = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setTimeout(() => {
      setHideContent(false)
    }, 500);
  };

  const getRandomVideo = () => {
    const randomNumber = Math.floor(Math.random() * 3) + 1;
    switch (randomNumber) {
      case 1: 
        return 1;
      case 2:
        return 2;
      case 3:
        return 3;
      default:
        return 1;
    }
  };
  

  useEffect(() => {
    if (id) {
      dispatch(fetchReleaseById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && playerReleaseId && id === playerReleaseId) {
      if (isPlaying) {
        setHideContent(true)
      }
    } else {
      setHideContent(false)
    }
  // eslint-disable-next-line
  }, [playerReleaseId, id]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
    

    setBgPlayer(getRandomVideo());
  }, []);

  useEffect(() => {
    if (hideContent) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [hideContent]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className={`relative min-h-screen ${hideContent ? 'z-50' : 'z-10'}`}>
      {release && (
        <div key={release.id} className='text-white text-center'>
          <div
            ref={containerRef}
            className={`pb-28 lg:pb-20 w-full ${hideContent ? 'fixed h-screen overflow-scroll' : 'relative'}`}
          >
            <video
              src={big_player_gradient1}
              className={`${bgPlayer === 1 ? 'block' : 'hidden'} fixed top-0 bottom-0 left-0 right-0 w-full h-full object-cover ${hideContent ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000 ease-in-out`} style={{zIndex: -1}}
              ref={videoRef1}
              autoPlay
              muted
              loop
              playsInline
            />
            <video
              src={big_player_gradient2}
              className={`${bgPlayer === 2 ? 'block' : 'hidden'} fixed top-0 bottom-0 left-0 right-0 w-full h-full object-cover ${hideContent ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000 ease-in-out`} style={{zIndex: -1}}
              ref={videoRef2}
              autoPlay
              muted
              loop
              playsInline
            />
            <video
              src={big_player_gradient3}
              className={`${bgPlayer === 3 ? 'block' : 'hidden'} fixed top-0 bottom-0 left-0 right-0 w-full h-full object-cover ${hideContent ? 'opacity-100' : 'opacity-0'} transition-opacity duration-1000 ease-in-out`} style={{zIndex: -1}}
              ref={videoRef3}
              autoPlay
              muted
              loop
              playsInline
            />
            <div className={`relative z-10`}>
              { hideContent ? (
                  <img
                    src='/acid-discos-powered-by-mm.png'
                    className={`mx-auto mt-16 mb-10 w-20 ${hideContent ? 'opacity-100' : 'opacity-0'} transition-opacity duration-500 ease-in-out`}
                    alt='Acid Discos powered by MM'
                  />
                ) : (
                  <div className="mt-32 mb-10">{release.cat_number}</div>
                )
              }
              {/* <h1 className='text-6xl lg:text-7xl leading-none -mb-4'>{release.artist}</h1>
              <h2 className='text-6xl lg:text-7xl leading-none mb-6'>{release.title}</h2> */}
              <h1 className='text-6xl lg:text-7xl leading-[2.55rem] lg:leading-none -mb-4'>{release.artist}</h1>
              <h2 className='text-6xl lg:text-7xl leading-[6rem] lg:leading-none mb-6'>{release.title}</h2>
              <p className='text-base lg:text-xl sm: leading-[.8] lg:leading-[.8] px-4 lg:px-6'>
                All Tracks Written, Produced & Mixed by {release.produced_by}<br />
                Recorded at {release.recorded_at}<br />
                Mastered by {release.mastered_by}<br />
                Distributed by Word and Sound<br />
                Graphics & Design by J.Diaz | allthatjazz<br />
                A & R by Moon & Mann
              </p>
              <div className={`mt-10 ${hideContent ? 'mb-12'  : ''} flex flex-col items-center`}>
                <div>
                  {release.tracklist.map((track, index) => (
                    <button
                      key={index}
                      onClick={() => playTrackFromIndex(index)}
                      className='uppercase text-left text-base lg:text-xl flex items-center gap-3'
                    >
                      {currentIndex === index && isPlaying ? (
                        <PauseCircleIcon aria-hidden="true" className="h-8 w-18" />
                      ) : (
                        <PlayCircleIcon aria-hidden="true" className="h-8 w-18" />
                      )}
                      {track.track_name} {track.bpm}
                    </button>
                  ))}
                </div>
                { hideContent && (
                  <div
                    className='text-center mt-10 cursor-pointer hover:underline'
                    onClick={closePlayer}
                  >
                    CLOSE
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={`${hideContent ? 'hidden' : 'block'}`}>
            <div className='flex flex-col md:flex-row gap-6 px-4 lg:px-6'>
              <div className=''>
                <UrlFirebase url={release.cover} className="w-full object-cover" />
              </div>
              <div className=''>
                <UrlFirebase url={release.cover_back} className="w-full object-cover" />
              </div>
            </div>
            <p className='text-base lg:text-xl mt-10 mb-32 px-2 lg:px-4 mx-auto max-w-2xl text-neutral-600 leading-[.8] lg:leading-[.8]'>
              Gear Used: {release.gear_used}
            </p>
            <div className="w-full mb-24">
              <div className="w-full mb-4 mt-64 px-4 lg:px-6">
                <div className="relative w-full h-[304px]">
                  <img src={Banner_Footer_BW_New} alt="Promo Banner 01" className="absolute inset-0 w-full h-full object-cover" />
                </div>
              </div>  
              <div className="flex flex-col lg:flex-row justify-center items-center gap-4 px-4 lg:px-6">
                <div className="">
                  <img src={Banner_Outro_Left_BW} alt="Promo Banner 01" className="w-full" />
                </div>
                <div className="">
                  <img src={Banner_Outro_BW} alt="Promo Banner 02" className="w-full" />
                </div>
                <div className="">
                  <img src={Banner_Outro_Right_BW} alt="Promo Banner 03" className="w-full" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Release;
