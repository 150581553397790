import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { setPlayerReleaseId, setTrackList, setCurrentIndex, setIsPlaying, setPlayerDisplay } from '../../features/audioplayer/playerSlice';
import { useDispatch } from 'react-redux';
import UrlFirebase from '../../components/firebase/urlFirebase';

const ReleasesHomeCard = ({ data }) => {

  const navigate = useNavigate()
  const handleClick = (id) => {
    navigate(`/release/${id}`)
  }
  const dispatch = useDispatch();
  

  const playRelease = (id, tracklist,) => {
    dispatch(setPlayerReleaseId(id));
    dispatch(setTrackList(tracklist));
    dispatch(setPlayerDisplay(true));
    dispatch(setCurrentIndex(0));
    dispatch(setIsPlaying(true));
  }
  

  useEffect(() => {}, [data]);

  return (
    <div className="flex flex-col text-lg">
      <div className="flex-shrink-0">
      {data.released === 1 && (
        <button className="block sm:hidden" onClick={() => handleClick(data.id)}>
          <UrlFirebase url={data.cover_home} className="w-full object-cover" />
        </button>
      )}
      <button className="hidden sm:block" onClick={() => handleClick(data.id)}>
        <UrlFirebase url={data.cover_home} className="w-full object-cover" />
      </button>
    </div>
      {data.released === 1 && (

        <div className="flex flex-1 mt-3 text-xl">  
          <div className="flex justify-between w-full text-white font-bold">
            <button className="flex hover:blur-[3px]" onClick={() => playRelease(data.id, data.tracklist)} >
              PLAY 
            </button>
            <button onClick={() => handleClick(data.id)} className="flex hover:blur-[3.5px]">
              INFO
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReleasesHomeCard;
